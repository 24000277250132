<template>
  <b-card style="min-width: 40vw" no-body>
    <template slot="header">
      <div class="d-flex justify-content-between">
        <div><strong>BetaSafe AuthCtl Login</strong></div>
        <div>{{tenant.name}}</div>
      </div>
    </template>
    <b-overlay :show="busy">
      <b-card-body>
        <b-card-text>
          <p>Enter your login credentials:</p>

          <b-alert variant="danger" show v-if="error"><b>Error:</b> {{error}}</b-alert>

          <b-input
            ref="username"
            v-model="credentials.username"
            type="email"
            placeholder="Username"
            class="mb-2"
            autofocus
            @keypress.enter.prevent="$refs.pwd.select()"
          />

          <b-input
            ref="pwd"
            v-model="credentials.password"
            type="password"
            placeholder="Password"
            class="mb-2"
            @keypress.enter.prevent="login()"
          />
          <b-btn variant="primary" @click="login" :disabled="!canLogin" class="mr-2"><icon-text icon="key">Login</icon-text></b-btn>
          <b-btn variant="secondary" @click="forgottenPassword" :disabled="!canRetrievePassword">Forgotten Password</b-btn>
          <hr>
          <div class="text-center">
            <p>or...</p>
            <b-btn size="lg" variant="danger" @click="googleLogin()"><icon-text :icon="['fab', 'google']">Login with Google</icon-text></b-btn>
          </div>
        </b-card-text>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import IconText from '@/components/IconText'
import { mapGetters } from 'vuex'

export default {
  components: { IconText },
  data: () => ({
    busy: false,
    error: null,
    credentials: {
      username: '',
      password: ''
    }
  }),
  computed: {
    ...mapGetters({
      tenant: 'tenant/tenant'
    }),
    canLogin () {
      return this.credentials.username !== '' && this.credentials.password !== ''
    },
    canRetrievePassword () {
      return this.credentials.username !== '' && this.credentials.password === ''
    }
  },
  methods: {
    async login () {
      this.busy = true
      this.error = null
      try {
        await this.$store.dispatch('auth/authenticate', { method: 'internal', ...this.credentials })
        this.$router.push({ name: 'dashboard' })
      } catch (error) {
        if (error.response.status === 403) {
          this.error = 'Username or password incorrect.'
        } else if (error.response.data && error.response.data.message) {
          this.error = error.response.data.message
        } else {
          this.error = error
        }

        this.$refs.username.select()
      } finally {
        this.busy = false
      }
    },
    async forgottenPassword () {
      if (await this.$store.dispatch('dialog/showConfirm', { title: 'Forgotten Password', message: 'Are you sure you want to initiate the forgotten password procedure?' })) {
        this.busy = true
        this.error = null
        try {
          await this.$store.dispatch('auth/forgottenPassword', { username: this.credentials.username })
          this.$store.dispatch('dialog/showInfo', { title: 'Forgotten Password', message: 'An email has been sent to the registered email address of the supplied username.' })
        } catch (error) {
          this.error = error
          this.$refs.username.select()
        } finally {
          this.busy = false
        }
      }
    },
    async googleLogin () {
      this.busy = true
      this.error = null

      try {
        const googleUser = await this.$gAuth.signIn()
        const result = await this.$store.dispatch('auth/authenticate', { method: 'google', id_token: googleUser.getAuthResponse().id_token })

        if (result.result === 'enrol') {
          this.$router.push({ name: 'enrol', hash: '#' + result.token })
        } else {
          this.$router.push({ name: 'dashboard' })
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          this.error = error.response.data.message
        } else if (error.error) {
          switch (error.error) {
            case 'popup_closed_by_user':
              this.error = 'Google login cancelled'
              break
            default:
              this.error = error.error
              break
          }
        } else {
          this.error = error
        }

        this.$refs.username.select()
      } finally {
        this.busy = false
      }
    }
  }
}
</script>
